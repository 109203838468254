import { ref, reactive, watch, onMounted } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
// import store from '@/store'
// 接口地址
import {
  staffVideoListApi,
  staffVideoVerifyApi,
  staffVideoStatusApi,
  staffVideoCategoryApi,
} from '@/api/staffZiyuan'
// 消息提醒
import MsgNotice from '@/comps/MsgNotice'

export default function useFunction($can) {
  const { router } = useRouter()
  /* 页面文本信息 后期 多语言预留 */
  const moduleName = ref('staffImage')
  const moduleText = '资源'

  // const statusOptions = [
  //   { id: 0, label: '下架中' },
  //   { id: 1, label: '上架中' },
  // ]

  const verifyStatusOptions = [
    { id: 0, label: '未审核' },
    { id: 1, label: '已审核' },
    { id: -1, label: '不通过' },
  ]

  // 查询参数，自动生成界面
  const searchParamsBase = [
    { param: 'staff_name', component: 'input', type: 'text', label: '员工名称', value: '', placeholder: '请输入员工姓名' },
    { param: 'category_id', component: 'select', label: '分类名称', selectOptions: [], value: 0, },
    { param: 'staff_phone', component: 'input', type: 'text', label: '员工手机', value: '', },
    // { param: 'status', component: 'select', label: '上架状态', selectOptions: statusOptions, selectLabel: 'label', value: '', },
    { param: 'verify_status', component: 'select', label: '审核状态', selectOptions: verifyStatusOptions, selectLabel: 'label', value: '', },
  ]
  /* 页面文本信息 后期 多语言预留 */

  // 左侧按钮配置
  const buttonLeftOptions = [
    // { label: '导入', variant: 'outline-primary', icon: 'UploadCloudIcon', event: 'upload' },
    // { label: '下载', variant: 'outline-primary', icon: 'DownloadCloudIcon', event: 'download' },
  ]
  // 右侧按钮配置
  const buttonRightOptions = [
    // { label: '新建资源', variant: 'primary', icon: 'PlusIcon', event: 'add' },
    // { label: '导入', variant: 'outline-primary', icon: 'UploadCloudIcon', event: 'upload' },
  ]

  // 表格操作菜单
  const menuData = [
    // { clickType: 'edit', icon: 'EditIcon', text: '编辑', rule: { action: 'read', subject: 'Auth' } },
    { clickType: 'video', icon: 'PlayIcon', text: '播放', },
    { clickType: 'enable', icon: 'UnlockIcon', text: '启用', status: [true], field: 'status', rule: { action: 'status', subject: 'extension-resources-video' } },
    { clickType: 'disable', icon: 'LockIcon', text: '禁用', status: [false], field: 'status', rule: { action: 'status', subject: 'extension-resources-video' } },
    { clickType: 'verify', icon: 'CheckCircleIcon', text: '审核', status: [0], field: 'verifyStatus', rule: { action: 'verify', subject: 'extension-resources-video' } },
  ]

  // 接口提示信息
  const MsgText = {
    // 获取数据
    fetchData: {
      success: { title: '', text: '' },
      fail: { title: `${moduleText}列表获取失败`, text: null }
    },
    // 状态管理
    statusEvent: {
      success: { title: `${moduleText}状态更新成功`, text: null },
      fail: { title: `${moduleText}状态更新失败`, text: null }
    },
    // // 新增编辑
    // editEvent: {
    //   success: { title: `${moduleText}编辑成功`, text: null },
    //   fail: { title: `${moduleText}编辑失败`, text: null }
    // },
    // 审核操作
    verifyEvent: {
      success: { title: `${moduleText}审核成功`, text: null },
      fail: { title: `${moduleText}审核失败`, text: null },
      confirm: { title: `您确认需要审核此${moduleText}吗?`, text: '如果存在审核通过, 此资源将显示, 请知悉!' },
    },
  }

  /* 事件分发 */

  // 主控制按钮事件分发
  const mainActionEvent = type => {
    switch (type) {
      case 'add': null
        break
      case 'upload': null
        break
      case 'download': null
        break
      default: null
    }
  }

  // 组件事件分发
  const searhPanelButtonEvent = clickButtonType => {
    switch (clickButtonType) {
      case 'search': searchFunction()
        break
      case 'more': null
        break
      case 'reset': resetFunction()
        break
      default: null
    }
  }

  // 表格事件分发
  const tableActionEvent = (type, extra) => {
    switch (type) {
      case 'enable': statusEvent({ id: extra.id })
        break
      case 'disable': statusEvent({ id: extra.id })
        break
      case 'verify': ziyuanVerify(extra)
        break
      case 'video': showVideo(extra)
        break
      default: null
    }
  }

  /* 权限控制 */

  // 页面启动加载接口
  onMounted(() => { $can('list', 'extension-resources-video') ? fetchData(searchParams) : MsgNotice('权限告警', '未获得资源列表的权限') })
  onMounted(() => { $can('select', 'extension-resources-video') ? selectCate() : MsgNotice('权限告警', '未获得视频分类列表的权限') })

  /* 表格相关操作属性及数据 */

  // 适配表格
  const cellClassName = ({ column }) => {
    if (
      column.property === 'action' ||
      column.property === 'srcUrl' ||
      column.property === 'composeUrl' ||
      column.property === 'status' ||
      column.property === 'verifyStatus'
    ) {
      return 'vxe-table-action-padding'
    }
  }

  // 查询参数
  const paramsBase = { page: 1, size: 10 }
  // 查询对象 添加自定义查询
  searchParamsBase.forEach(el => { paramsBase[el.param] = el.value })
  const searchParams = reactive(JSON.parse(JSON.stringify(paramsBase)))
  // 设置响应式查询组件参数变量
  const searchParamsObject = ref(JSON.parse(JSON.stringify(searchParamsBase)))

  // vxe-table 加载缓冲控制
  const loading = ref(false)
  // vxe-table 加载缓冲控制
  const xTable = ref(null)
  // 表格数据源
  const tableData = ref([])
  // 记录条数
  const total = ref(0)

  // 更改菜单的可用性 涉及权限，状态属性等
  const setMenuDisabled = row => {
    const menuInfo = JSON.parse(JSON.stringify(menuData))
    menuInfo.forEach(item => {
      // 判断是否被状态禁用
      const customerDisabled = item.status ? !item.status.includes(row[item.field]) : true
      // 判断是否被权限禁用
      const rule = item.rule ? $can(item.rule.action, item.rule.subject) : true
      // 设置菜单的可用性
      item.disabled = customerDisabled && rule
      return item
    })
    return menuInfo.filter(item => item.disabled)
  }

  // 审核操作
  const shallShowModalVerify = ref(false)
  const verifyExtra = ref({})
  const ziyuanVerify = data => {
    shallShowModalVerify.value = true
    verifyExtra.value = data
  }

  // 视频播放
  const shallShowModalVideo = ref(false)
  const videoUrl = ref('')
  const showVideo = extra => {
    videoUrl.value = extra.videoUrl
    shallShowModalVideo.value = true
  }

  /* 接口调用，抽离参数，函数等 */

  // 列表获取接口
  const fetchData = () => {
    staffVideoListApi(searchParams)
      .then(res => {
        tableData.value = res.data.list
        total.value = res.data.total
      })
      .catch(err => { MsgNotice(MsgText.fetchData.fail.title, MsgText.fetchData.fail.text || err.msg) })
  }

  // 状态更新接口
  const statusEvent = id => {
    staffVideoStatusApi(id)
      .then(res => {
        fetchData()
        MsgNotice(
          MsgText.statusEvent.success.title,
          MsgText.statusEvent.success.text || res.msg,
          'CheckCircleIcon',
          'success',
        )
      })
      .catch(err => { MsgNotice(MsgText.statusEvent.fail.title, MsgText.statusEvent.fail.text || err.msg) })
  }

  // 审核接口
  const verifyEvent = (data, extra) => {
    staffVideoVerifyApi({ id: extra.id, verifyRemark: data.remark, verifyStatus: data.status })
      .then(res => {
        fetchData()
        MsgNotice(
          MsgText.verifyEvent.success.title,
          MsgText.verifyEvent.success.text || res.msg,
          'CheckCircleIcon',
          'success',
        )
      })
      .catch(err => { MsgNotice(MsgText.verifyEvent.fail.title, MsgText.verifyEvent.fail.text || err.msg) })
  }

  // 下拉查询分类
  const selectCate = () => {
    staffVideoCategoryApi()
      .then(res => {
        searchParamsBase.forEach(item => {
          if(item.param === 'category_id') item.selectOptions = res.data
        })
        resetFunction()
      })
  }

  /* 页面生命周期管理，watch, onMounted, nextTick */

  // 处理参数查询
  watch(() =>[searchParams.page, searchParams.size], () => { fetchData(searchParams) })
  // watch(searchParams, () => { fetchData(searchParams) }, { deep: true })

  // 重置参数 操作处理
  const resetFunction = () => { searchParamsObject.value = JSON.parse(JSON.stringify(searchParamsBase)) }
  // 查询组件 操作处理
  const searchFunction = () => {
    searchParamsObject.value.forEach(el => { searchParams[el.param] = el.value })
    searchParams.page === 1 ? fetchData(searchParams) : searchParams.page = 1
  }

  return {
    // 文本信息
    moduleName,
    moduleText,
    // 表格呈现
    cellClassName,
    loading,
    tableData,
    xTable,
    tableActionEvent,

    // 查询模块
    searchParams,
    searchParamsObject,
    total,
    
    // 接口函数
    fetchData,
    // editEvent,
    verifyEvent,
    statusEvent,
    // 页面事件
    menuData,
    searhPanelButtonEvent,
    setMenuDisabled,
    // 主事件控制区
    buttonLeftOptions,
    buttonRightOptions,
    mainActionEvent,

    shallShowModalVerify,
    verifyExtra,
    ziyuanVerify,

    shallShowModalVideo,
    videoUrl,
  }
}
