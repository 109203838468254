<template>
  <b-modal
    id="video-modal"
    :visible="shallShowModal"
    centered
    no-close-on-backdrop
    hide-footer
    static
    @change="(val) => $emit('update:shall-show-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title"> 视频播放器 </h5>
      <div class="modal-actions">
        <feather-icon icon="XIcon" class="ml-1 cursor-pointer" @click="$emit('update:shall-show-modal', false)" />
      </div>
    </template>
    <div>
      <video-player
        v-if="url"
        class="video-player vjs-custom-skin" 
        ref="videoPlayer" 
        :playsinline="false" 
        :options="playerOptions">
      </video-player>
    </div>
  </b-modal>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
// require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')

export default {
  components: {
    videoPlayer,
  },
  model: {
    prop: 'shallShowModal',
    event: 'update:shall-show-modal',
  },
  props: {
    shallShowModal: {
      type: Boolean,
      required: true,
    },
    url: {
      type: [String, Array],
      required: true,
    },
    options: {
      type: Object,
      required: false,
    },
  },
  setup(props, { emit }) {
    /* 必要组件加载区域 */

    // 权限组件 弹出确认组件

    /* 组件页面数据准备区域，包括表单，下拉选择项，数据整理操作等 */

    // 表单验证dom对象
    const videoPlayer = ref(null)
    // 数据项
    const playerOptions = ref({
      playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
      autoplay: false, // 如果为true,浏览器准备好时开始回放。
      muted: false, // 默认情况下将会消除任何音频。
      loop: false, // 是否视频一结束就重新开始。
      preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
      language: 'zh-CN',
      aspectRatio: '9:16', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
      fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
      sources: [{
        type: "video/mp4", // 类型
        src: '' // url地址
      }],
      poster: '', // 封面地址
      notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
      controlBar: {
        timeDivider: true, // 当前时间和持续时间的分隔符
        durationDisplay: true, // 显示持续时间
        remainingTimeDisplay: false, // 是否显示剩余时间功能
        fullscreenToggle: true // 是否显示全屏按钮
      }
    })

    /* 接口调用区域 */

    /* 组件内部逻辑处理，生命周期问题处理区域 */
    watch(() => props.url, () => { playerOptions.value.sources[0].src = props.url })
    watch(() => props.shallShowModal, val => { if (!val) videoPlayer.value.player.pause() })

    return {
      videoPlayer,
      playerOptions,
    }
  },
}
</script>
<style lang="scss">

  #video-modal .modal-body { padding: 0; }

  .vjs-custom-skin {
    height: 100%;

    .video-js {
      width: 100%;
      min-height: 300px;

      .vjs-big-play-button {
        /*对播放按钮的样式进行设置*/
        width: 80px;
        height: 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%,-50%,0);
        margin: 0 !important;
      }
    }
  }

</style>
